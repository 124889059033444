import React, { FC, Fragment } from 'react';
import ICONS from 'components/Icons';
import ToggleSwitch from 'components/ToggleSwitch';
import { PriceSettings } from 'api/data/pages/types';
import { ProcessingFeePayer } from '~/api/data/pages/types';
import { FeePayer } from 'api/data/user/types';
import './style.scss';

type PricingSettingsContentProps = {
  page: { processingFeePayer?: ProcessingFeePayer | FeePayer; tipsEnabled?: boolean };
  onChange: (attrs: PriceSettings) => void;
};

const payerPageOptionsDescriptions = {
  PAYER: 'Payers will be charged for card processing or can choose to pay with ACH Bank transfer for free.',
  ORGANIZATION: 'Your organization will cover card processing so that payers are not charged a fee.',
};

const tipsPageOptionsDescriptions = {
  ON: 'Payers will be asked to leave an optional tip, which means your organization will not be charged a platform fee.',
  OFF: 'Payers will not be asked to leave an optional tip. Instead, your organization will be charged a platform fee per payment.',
};

const PricingSettingsContent: FC<PricingSettingsContentProps> = ({ page, onChange }) => {
  return (
    <>
      <div className="settings-content">
        <div className="row">
          {ICONS['fees_money_hand']}
          <p className="title">Card Processing Fee</p>
        </div>

        <p>You&apos;re in control: as an organization, you choose who covers card processing fees.</p>

        <div className="payer-options">
          {[
            {
              payer: ProcessingFeePayer.payer,
              label: 'Payers',
            },
            {
              payer: ProcessingFeePayer.organization,
              label: 'Your organization',
            },
          ].map(element => (
            <Fragment key={element.payer}>
              <input
                type="radio"
                id={`processingFeePayer-${element.payer}`}
                name={`processingFeePayer-${element.payer}`}
                checked={page.processingFeePayer === element.payer}
                onChange={() => onChange({ processingFeePayer: element.payer })}
              />
              <label htmlFor={`processingFeePayer-${element.payer}`}>{element.label}</label>
            </Fragment>
          ))}
        </div>

        <p className="callout">
          {ICONS['solid_info']}
          {page.processingFeePayer && payerPageOptionsDescriptions[page.processingFeePayer]}
        </p>
      </div>

      <div className="settings-content">
        <div className="tips-header-container row">
          <div className="row tips-title">
            {ICONS['pig']}
            <p className="title">Tips</p>
          </div>
          <div className="tips-toggle-container">
            <ToggleSwitch
              toggleValue={page.tipsEnabled || false}
              ariaLabel="tips on"
              label={page.tipsEnabled ? 'On' : 'Off'}
              labelPosition="after"
              handleOnChange={() => onChange({ tipsEnabled: !page.tipsEnabled })}
            />
          </div>
        </div>

        <p>{tipsPageOptionsDescriptions[page.tipsEnabled ? 'ON' : 'OFF']}</p>
      </div>
    </>
  );
};

export default PricingSettingsContent;
