import React, { useState } from 'react';
import { useParams } from 'wouter';
import Badge from '../Badge';
import Message from '../Message';
import PricingSettingsContent from '../PricingSettings/PriceSettingsContent';
import Receipt from '../Receipt';
import SideModal from '../SideModal';
import SideModalHeader from '../SideModal/Header';
import Tabs from '../Tabs';
import Tab from '../Tabs/Tab';
import TeamSettings from './TeamSettings';
import { PageDataDraftBlock } from '~/api/data/pages/types';
import './style.scss';

interface PageSettingsProps {
  page: PageDataDraftBlock;
  isSettingsOpen: boolean;
  toggleSettings: (isOpen?: boolean) => void;
  handleConfigChanges: (changes: Partial<PageDataDraftBlock>) => void;
}

export default function PageSettings({ page, isSettingsOpen, toggleSettings, handleConfigChanges }: PageSettingsProps) {
  const [tabContentKey, setTabContentKey] = useState('pricing');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const { requestId } = useParams();

  const onConfigChanges = (changes: Partial<PageDataDraftBlock>) => {
    handleConfigChanges(changes);
    toggleSettings(false);
    setShowMessage(true);
  };

  const content: {
    [key: string]: JSX.Element;
  } = {
    pricing: (
      <div className="price-settings">
        <PricingSettingsContent page={page} onChange={handleConfigChanges} />
        <div className="bottom-content">
          <a href="https://www.omella.com/blog/pricing" target="_blank" rel="noreferrer">
            Learn more about pricing
          </a>
        </div>
      </div>
    ),
    receipt: (
      <Receipt
        subject={page}
        subjectType="page"
        onSave={changes => {
          setMessage('Receipt updated!');
          onConfigChanges(changes as Partial<PageDataDraftBlock>);
        }}
        loading={false}
      />
    ),
    team: (
      <TeamSettings
        page={page}
        onChange={changes => {
          setMessage('Team settings updated!');
          onConfigChanges(changes);
        }}
      />
    ),
  };

  return (
    <>
      <Message className="receipt-changed" showMessage={showMessage} setShowMessage={setShowMessage}>
        {message}
      </Message>
      <SideModal
        isOpen={isSettingsOpen}
        toggle={toggleSettings}
        header={<SideModalHeader title="Settings" onClose={() => toggleSettings(false)} />}>
        <div className="page-settings-menu">
          <Tabs activeKey={tabContentKey}>
            <Tab label="Pricing" tabKey="pricing" onClick={setTabContentKey} />
            <Tab label="Receipt" tabKey="receipt" onClick={setTabContentKey} />
            {!requestId && (
              <Tab label="Team" tabKey="team" onClick={setTabContentKey} suffix={<Badge label="Beta" />} />
            )}
          </Tabs>
        </div>
        <div className="settings-container">{content[tabContentKey]}</div>
      </SideModal>
    </>
  );
}
