import React, { FC, useState, useContext } from 'react';
import dayjs from 'dayjs';
import { Subscription } from 'api/data/subscription/types';
import { TransactionByUser } from 'api/data/transaction/types';
import { User } from 'api/data/user/types';
import UserRolePerspective from 'contexts/UserRolePerspective';
import { mapStatusClass, statusTranslator } from 'defaults/subscription';
import { currencyFormatter, subscriptionEnded } from 'utils';
import CancelSubscriptionModal, { showCancelationButton } from 'components/CancelSubscriptionModal';
import ICONS from 'components/Icons';
import { OrderItem } from './OrderItem';
import PaymentMethod from 'pages/MemberProfile/PaymentMethod';
import PayerRequestedModal from '../RefundModal/PayerRequestedModal';
import ReviewRequestModal from 'components/RefundModal/ReviewRequestModal';
import Tag from '../Tag';
import UserLink from '../UserLink';
import { PaymentMethodType } from '~/api/data/payment/types';
import './style.scss';

interface SubscriptionDetailType {
  payer?: User;
  subscription?: Subscription;
  onSubscriptionsChanged?: () => Promise<unknown>;
  lastTransaction?: TransactionByUser;
}

const SubscriptionDetail: FC<SubscriptionDetailType> = ({
  payer,
  subscription,
  onSubscriptionsChanged,
  lastTransaction,
}) => {
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const subscriptionStatus = subscription?.status ? statusTranslator[subscription?.status] : '';
  const nextOccurrence =
    subscription?.currentInvoiceOccurrence && subscription?.currentInvoiceOccurrence > 0
      ? subscription?.currentInvoiceOccurrence + 1
      : undefined;
  const isPayerView = useContext(UserRolePerspective) === 'payer';

  const amount = isPayerView ? subscription?.totalAmountCents : subscription?.netAmountCents;

  const showProcessingFees =
    (isPayerView && subscription?.processingFeePayer === 'payer') ||
    (!isPayerView && subscription?.processingFeePayer === 'organization');

  const processingFee = showProcessingFees ? subscription?.processingFeeAmountCents : 0;

  const formatFee = (feeAmount: number) => {
    if (feeAmount === 0) return 'Free';
    const formatted = currencyFormatter(feeAmount);
    return isPayerView ? formatted : `- ${formatted}`;
  };

  return (
    <div className="subscription-detail-content">
      <div className="header">
        <div className="details">
          <p className="title">About this payment</p>
          <div className="content">
            <p className="item">
              Amount
              <span>
                {currencyFormatter(amount)}{' '}
                {subscription && <Tag type={mapStatusClass[subscription.status]} label={subscriptionStatus} />}
              </span>
            </p>
            <p className="item">
              Page <span>{subscription?.page?.title}</span>
            </p>
          </div>
          <div className="content">
            <div className="date-content">
              <p className="item">
                Started <span>{dayjs(subscription?.insertedAt).format('MMM D, YYYY')}</span>
              </p>
              {subscription?.canceledAt && (
                <p className="item">
                  Canceled <span>{dayjs(subscription?.canceledAt).format('MMM D, YYYY')}</span>
                </p>
              )}
            </div>
            {isPayerView && (
              <p className="item">
                Organization <span>{subscription?.organization?.name}</span>
              </p>
            )}
          </div>
          <div>
            {subscription?.hasPendingPayerRequest && isPayerView && (
              <PayerRequestedModal
                requestedDate={subscription?.pendingPayerRequest?.requestedAt}
                organization={subscription?.organization}
                requestType="Cancelation"
              />
            )}
            <CancelSubscriptionModal
              subscription={subscription}
              showButton={!!subscription && showCancelationButton(subscription)}
              lastTransaction={lastTransaction}
              userId={payer?.id}
            />
            <ReviewRequestModal
              pageTitle={subscription?.page?.title}
              requesterName={subscription?.user?.fullName}
              amountCents={subscription?.pendingPayerRequest?.amountCents}
              showButton={!!subscription?.hasPendingPayerRequest && !isPayerView}
              status={subscription?.lastRespondedPayerRequest?.status}
              userId={payer?.id}
              subscriptionId={subscription?.id}
              recurring={subscription?.recurring}
              cancelationReason={subscription?.pendingPayerRequest?.cancelationReason}
            />
          </div>
        </div>
        <div className="billing-info">
          <p className="title">Billing info</p>
          <div>
            <div className="item">
              Payer
              <span>
                <UserLink
                  fullName={payer?.fullName || ''}
                  email={payer?.email || ''}
                  userId={payer?.id || ''}
                  separator={<> - </>}
                />
              </span>
            </div>
          </div>
          <div>
            <div className="item payment-method-info">
              Payment method
              <span>
                {subscription?.paymentMethodType === PaymentMethodType.ACH ? (
                  `${subscription?.bankName?.toUpperCase() || ''} •••• ${subscription?.last4 || ''} `
                ) : (
                  <span>
                    {ICONS[subscription?.cardBrand?.toLocaleLowerCase() || '']}
                    {`•••• ${subscription?.last4 || ''} `}
                  </span>
                )}
                {isPayerView && !subscriptionEnded(subscription) && (
                  <>
                    <button className="button-link" onClick={() => setShowPaymentMethodModal(true)}>
                      Change
                    </button>
                    <PaymentMethod
                      subscription={subscription}
                      visible={showPaymentMethodModal}
                      setShowModal={setShowPaymentMethodModal}
                      onClose={() => setShowPaymentMethodModal(false)}
                      onPaymentMethodChanged={onSubscriptionsChanged}
                    />
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      {!subscriptionEnded(subscription) && (
        <div className="payment-detail">
          <p className="title">Next payment</p>
          <div className="next-payment-content">
            <p>
              {dayjs(subscription?.nextChargeAt).format('MMM D, YYYY')} <span>{currencyFormatter(amount)}</span>
            </p>
            <div>
              <div className="order-detail">
                {subscription?.orderItems.map((orderItem, index) => (
                  <OrderItem
                    key={index}
                    orderItem={orderItem}
                    occurrence={subscription?.status === 'CANCELED' ? undefined : nextOccurrence}
                    recurring={subscription?.recurring}
                  />
                ))}
                <div className="amount-content">
                  <div>
                    <span>Subtotal</span>
                    <span>{currencyFormatter(subscription?.subtotalAmountCents)}</span>
                  </div>
                  {isPayerView && (
                    <div>
                      <span>Tips</span>
                      <span>{currencyFormatter(subscription?.tipAmountCents)}</span>
                    </div>
                  )}
                  {!isPayerView && (
                    <div>
                      <span>Platform Fee</span>
                      <span>{formatFee(subscription?.platformFeeAmountCents || 0)}</span>
                    </div>
                  )}
                  <div>
                    <span>Processing fee</span>
                    <span>{formatFee(processingFee)}</span>
                  </div>
                  {!isPayerView && (
                    <div>
                      <span>Recurring Fee</span>
                      <span>{formatFee(subscription?.recurringFeeAmountCents || 0)}</span>
                    </div>
                  )}

                  <div className="horizontal-line"></div>
                  <div className="payment-info">
                    <span>Total</span>
                    <span className="last-4">
                      {subscription?.paymentMethodType === PaymentMethodType.ACH ? (
                        <>{`${subscription?.bankName?.toUpperCase() || ''} •••• ${subscription?.last4 || ''} `}</>
                      ) : (
                        <>
                          {ICONS[subscription?.cardBrand?.toLocaleLowerCase() || '']}
                          {`•••• ${subscription?.last4 || ''} `}
                        </>
                      )}
                    </span>
                    <span className="amount">{currencyFormatter(amount)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionDetail;
