import React, { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import { removeOrderItem, updateOrderItem, findOrderItem } from 'api/data/response';
import { defaultBlocksTitle, RecurringValue } from 'defaults/page';
import { numberCentsFormatter, filterDuplicated, getRecurringName, isValidPaymentAmount } from 'utils';
import PayerViewImage from 'components/ImageInBlock/PayerView';
import RecurringBlockHeader from '../Header/RecurringBlockHeader';
import VariantQuantity from '../Variant/VariantQuantity';
import { DonationPayerViewProps, Variant } from './types';
import './style.scss';

const DonationPayerView: FC<DonationPayerViewProps> = ({ data, readOnly, isRequired }: DonationPayerViewProps) => {
  const variantAnyAmountRef = useRef<HTMLInputElement>(null);
  const orderItem = findOrderItem(data.id);

  const selectedRecurring = orderItem.recurring || data.recurring[0];
  const allowZeroVariant = selectedRecurring === 'ONCE';

  const handleVariantChange = (
    { id, label, amountCents, allowAnyAmount }: Variant,
    chosenValue: null | number | string = null,
  ) => {
    const amount = getAmount(chosenValue, allowAnyAmount);

    const newOrderItem = {
      ...orderItem,
      blockId: orderItem.blockId,
      blockTitle: data.description || '',
      blockType: data.type,
      recurring: orderItem.recurring || data.recurring[0],
      amount,
      unitPriceCents: amount,
      image: data.image,
      variant: {
        id,
        title: label,
        amount: amountCents ? parseFloat(amountCents as string) : 0,
        quantity: 1,
      },
    };

    updateOrderItem(orderItem.blockId, newOrderItem);
  };

  const getAmount = (chosenValue: null | number | string, allowAnyAmount: boolean) => {
    const isInvalidAmount =
      chosenValue === null ||
      (typeof chosenValue === 'string' && (chosenValue.trim() === '' || chosenValue.trim() === '.'));
    if (isInvalidAmount) {
      return null;
    }

    const amount = +chosenValue;
    return allowAnyAmount ? +(amount * 100).toFixed() : amount;
  };

  const handleRecurringChange = (value: RecurringValue) => {
    let newOrderItem = { ...orderItem, recurring: value };

    if (value !== 'ONCE' && orderItem.amount === 0) {
      newOrderItem = { ...newOrderItem, amount: null, variant: { ...newOrderItem.variant, id: '' } };
    }

    updateOrderItem(orderItem.blockId, newOrderItem);
  };

  useEffect(() => {
    if (!readOnly && orderItem.variant.id && variantAnyAmountRef && variantAnyAmountRef.current) {
      variantAnyAmountRef.current.focus();
    }
  }, [readOnly, orderItem.variant.id, variantAnyAmountRef]);

  const payerLabel =
    orderItem.recurring && orderItem.recurring !== 'ONCE' && `/${getRecurringName(orderItem.recurring)}`;

  const variantTitleCharacters = Math.max(...data.variants.map(el => el.label?.length || 0));
  const labelClassnames = clsx('label-container', {
    'empty-title': variantTitleCharacters === 0,
    'short-title': variantTitleCharacters > 0 && variantTitleCharacters < 31,
  });

  return (
    <div
      id={`block-${data.id}`}
      className={clsx('block donation preview', {
        'payer-view': !readOnly,
        'required-block': isRequired && orderItem.amount === null,
      })}>
      {data.recurring.length > 1 && (
        <RecurringBlockHeader
          recurringOptions={data.recurring}
          selected={orderItem.recurring || data.recurring[0]}
          readOnly={readOnly}
          handleOnChange={(value: RecurringValue) => handleRecurringChange(value)}
          blockId={data.id}
        />
      )}
      {data.required && (
        <span className={clsx('required-label', { 'has-header': data.recurring.length > 1 })}>
          {isRequired && orderItem.amount === null ? 'This is required*' : 'Required*'}
        </span>
      )}
      <div className="block-content">
        <h4 id={`donation_description_${data.id}`}>{data.description || defaultBlocksTitle[data.type]}</h4>
        <PayerViewImage blockType="donation" imageUrl={data.imageUrl} />
        <div className="row variant-container">
          {data.variants.filter(filterDuplicated).map(variant => {
            if (variant.allowAnyAmount) {
              return (
                <div key={variant.id} className="row align-center variant-box">
                  <input
                    type="radio"
                    id={variant.id}
                    name={`variant-${data.id}`}
                    checked={orderItem.variant.id === variant.id}
                    onChange={() => handleVariantChange(variant)}
                    className="hide"
                  />
                  {!readOnly && variant.id === orderItem.variant.id ? (
                    <span className={`row align-center ${labelClassnames}`}>
                      <label htmlFor={variant.id} id="other-amount">
                        Other
                      </label>
                      <span className="donation-label-box">
                        <span className="donation-label">$</span>
                        <NumericFormat
                          value={
                            orderItem.amount !== null &&
                            (allowZeroVariant || (!allowZeroVariant && orderItem.amount !== 0))
                              ? numberCentsFormatter(orderItem.amount)
                              : ''
                          }
                          onValueChange={target => {
                            handleVariantChange(variant, target.value);
                          }}
                          thousandSeparator
                          decimalScale={2}
                          className="input-amount"
                          name="variant"
                          aria-labelledby="other-amount"
                          isAllowed={isValidPaymentAmount}
                          getInputRef={variantAnyAmountRef}
                          placeholder=""
                          onBlur={() => updateOrderItem(orderItem.blockId, {})}
                        />
                        {payerLabel && <span className="recurring-label">{payerLabel}</span>}
                      </span>
                    </span>
                  ) : (
                    <label htmlFor={variant.id} className={`row align-center ${labelClassnames} other-amount`}>
                      <span className="other-amount">Other</span>
                      <div className="fixed-label">$</div>
                    </label>
                  )}
                </div>
              );
            }
            if (variant.amountCents === 0 && !allowZeroVariant) return null;
            else if (variant.amountCents !== null && variant.amountCents !== undefined)
              return (
                <div key={variant.id} className="row align-center justify-center variant-box">
                  <input
                    type="radio"
                    id={`donation_variant_option_${variant.id}`}
                    name={`variant-${data.id}`}
                    checked={orderItem.variant.id === variant.id}
                    onChange={() => handleVariantChange(variant, variant.amountCents)}
                    className="hide"
                  />
                  <label htmlFor={`donation_variant_option_${variant.id}`} className={labelClassnames}>
                    <span className="row align-baseline" id={`donation_variant_${variant.id}`}>
                      ${numberCentsFormatter(variant.amountCents as number)}
                      {payerLabel && <span className="recurring-label">{payerLabel}</span>}
                    </span>
                    {variant.label && <p id={`donation_variant_text_${variant.id}`}>{variant.label}</p>}
                  </label>
                  <VariantQuantity
                    variantId={variant.id}
                    allowAnyAmount={variant.allowAnyAmount}
                    isRequired={data.required}
                    orderItem={orderItem}
                  />
                </div>
              );
          })}
        </div>
        {!data.required && orderItem.variant.id && (
          <div className="clear-container">
            <button type="button" className="button-clear" onClick={() => removeOrderItem(data.id)}>
              Clear selection
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

DonationPayerView.defaultProps = {
  readOnly: false,
};

export default DonationPayerView;
