import React, { FC } from 'react';
import clsx from 'clsx';
import isURL from 'validator/lib/isURL';
import { findAnswer, updateAnswer } from 'api/data/response';
import { defaultBlocksTitle } from 'defaults/page';
import FileUpload from '../FileUpload';
import LongAnswer from '../LongAnswer';
import MultipleChoiceView from './MultipleChoice';
import Passcode from '../Passcode';
import ShortAnswer from '../ShortAnswer';
import Signature from '../Signature';
import SingleChoiceView from './SingleChoice';
import { QuestionPayerViewProps } from '../types';

const PayerView: FC<QuestionPayerViewProps> = ({
  readOnly,
  data,
  group,
  groupedAnswer,
  isRequired,
  saveGroupedAnswer,
  usedPasscodes = [],
  updateUsedPasscodes,
  pageId,
  parent,
}: QuestionPayerViewProps) => {
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, data.questionType);
  const props = {
    group,
    data,
    readOnly,
    groupedAnswer,
    saveGroupedAnswer,
    parent,
  };

  const handleAnswerChange = (value: string) => {
    if (group && group.id && saveGroupedAnswer) {
      saveGroupedAnswer(value);
    } else {
      updateAnswer(data.id, {
        value: value,
        question: {
          id: data.id,
          title: data.questionPrompt,
          type: data.questionType,
        },
        parent: parent
          ? {
              id: parent.id,
              blockId: parent.blockId || '',
              title: parent.label || '',
              type: 'VARIANT',
            }
          : undefined,
      });
    }
  };

  const handlePasscodeAnswerChange = (matchedPasscodeIds: string[], value: string) => {
    updateUsedPasscodes && updateUsedPasscodes(data.id, matchedPasscodeIds);
    handleAnswerChange(value);
  };

  const blockComponents = {
    FILE_UPLOAD: <FileUpload {...props} pageId={pageId} placeholder="Browse" />,
    SHORT_ANSWER: (
      <ShortAnswer
        {...props}
        placeholder="Type your answer here..."
        handleAnswerChange={handlePasscodeAnswerChange}
        usedPasscodes={usedPasscodes}
      />
    ),
    LONG_ANSWER: (
      <LongAnswer {...props} placeholder="Type your answer here..." handleAnswerChange={handleAnswerChange} />
    ),
    SINGLE_CHOICE: <SingleChoiceView {...props} />,
    MULTIPLE_CHOICE: <MultipleChoiceView {...props} />,
    SIGNATURE: <Signature {...props} placeholder="Type your full name here" handleAnswerChange={handleAnswerChange} />,
    PASSCODE: (
      <Passcode
        {...props}
        placeholder="Type your answer here..."
        handleAnswerChange={handlePasscodeAnswerChange}
        usedPasscodes={usedPasscodes}
      />
    ),
  };

  const isRequiredBlock =
    isRequired && (!answer.value || (data.questionType === 'FILE_UPLOAD' && !answer.file && !isURL(answer.value)));

  return (
    <div
      id={`block-${data.id}`}
      className={clsx('block payer-view question preview', {
        inner: !!group?.id || parent,
        nested: parent,
        'read-only': readOnly,
        'required-block': isRequiredBlock,
      })}>
      {data.required && <span className="required-label">{isRequiredBlock ? 'This is required*' : 'Required*'}</span>}
      <div className="block-content">
        <div className="default-question">
          <h4 className="block-title" data-id={`question_title_${data.id}`}>
            {data.questionPrompt || defaultBlocksTitle[data.questionType]}
          </h4>
          {data.description && (
            <span className="description" data-id={`question_description_${data.id}`}>
              {data.description}
            </span>
          )}
        </div>
        {blockComponents[data.questionType]}
      </div>
    </div>
  );
};

export default PayerView;
