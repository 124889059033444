import React, { SyntheticEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import useToggle from '~/hooks/useToggle';
import ICONS from '../Icons';
import Preview from './Preview';
import RichTextInput, { RichTextInputMethods } from '../RichTextInput';
import ToggleSwitch from '../ToggleSwitch';
import Tooltip from '../Tooltip';
import { PageDataDraftBlock } from 'api/data/pages/types';
import './style.scss';

interface PageReceiptProps {
  page: PageDataDraftBlock;
  loading: boolean;
  onSave: (receiptBody: string, receiptFooter: string, extraProps?: Record<string, unknown>) => void;
}

interface ReadOnlyInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
  value?: string;
}

export default function PageReceipt({ page, loading, onSave }: PageReceiptProps) {
  const [pageReceiptBody, setPageReceiptBody] = useState(page?.customBodyReceiptContent || '');
  const [pageReceiptFooter, setPageReceiptFooter] = useState(page?.customFooterReceiptContent || '');

  const [toggle, setToggle] = useToggle(page?.showCustomReceiptContent);

  const richTextBodyInputRef = useRef<RichTextInputMethods>(null);
  const richTextFooterInputRef = useRef<RichTextInputMethods>(null);

  const handleResetClick = () => {
    if (richTextBodyInputRef.current) {
      richTextBodyInputRef.current.reset();
    }

    if (richTextFooterInputRef.current) {
      richTextFooterInputRef.current.reset();
    }

    setToggle(page?.showCustomReceiptContent);
  };

  const { bodyPreview, footerPreview } = getPreviewContent();

  function getPreviewContent() {
    if (!toggle) {
      return {
        bodyPreview: page.organization?.customBodyReceiptContent || '',
        footerPreview: page.organization?.customFooterReceiptContent || '',
      };
    }

    return {
      bodyPreview: pageReceiptBody,
      footerPreview: pageReceiptFooter,
    };
  }

  const customBodyInputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
    className: clsx('default', { 'has-error': !pageReceiptBody }),
    id: 'body',
    placeholder: 'Please keep this receipt for your records.',
  };

  const customFooterInputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
    className: 'default',
    id: 'footer',
    placeholder: 'E.g. [org name] is a registered 501(c)(3) nonprofit organization, EIN: 12-2346789',
  };

  function ReadOnlyInput({ value, ...textAreaProps }: ReadOnlyInputProps) {
    return <RichTextInput {...textAreaProps} disabled initialValue={value} />;
  }

  return (
    <>
      <form
        className="receipt-settings"
        onSubmit={(event: SyntheticEvent) => {
          event.preventDefault();

          onSave(pageReceiptBody, pageReceiptFooter, { showCustomReceiptContent: toggle });
        }}
        aria-label="receipt form">
        <div className="content">
          <div>
            <div className="header-container">
              <div className="row">
                <h2>Customize this page&apos;s receipt</h2>
                <Tooltip
                  title="If you would like to update your organization's default receipt content, just head over to organization settings."
                  color="dark"
                  ariaLabel="receipt-info">
                  {ICONS['solid_help']}
                </Tooltip>
              </div>
              <ToggleSwitch
                toggleValue={toggle}
                handleOnChange={setToggle}
                label={toggle ? 'On' : 'Off'}
                labelPosition="after"
              />
            </div>
            {toggle ? (
              <p className="title-description">
                Turn this <strong>off</strong> if you would like to use your organization&apos;s default receipt
                content.
              </p>
            ) : (
              <p className="title-description">
                Turn this <strong>on</strong> if you would like to override your organization&apos;s default receipt
                content (shown below).
              </p>
            )}
            <div className="input first">
              {toggle ? (
                <RichTextInput
                  label="Body"
                  rules={{
                    required: true,
                    hasError: !pageReceiptBody,
                    errorMessage: 'This field is required',
                  }}
                  ref={richTextBodyInputRef}
                  {...customBodyInputProps}
                  parseToHTMLCallback={(htmlFragment: string) => {
                    setPageReceiptBody(htmlFragment);
                  }}
                  initialValue={page.customBodyReceiptContent || ''}
                  name="body"
                />
              ) : (
                <ReadOnlyInput
                  {...customBodyInputProps}
                  name="body"
                  label="Body"
                  value={page.organization?.customBodyReceiptContent}
                />
              )}
            </div>
            <div className="input">
              {toggle ? (
                <RichTextInput
                  ref={richTextFooterInputRef}
                  {...customFooterInputProps}
                  parseToHTMLCallback={(htmlFragment: string) => setPageReceiptFooter(htmlFragment)}
                  initialValue={page.customFooterReceiptContent || ''}
                  name="footer"
                  label="Footer"
                />
              ) : (
                <ReadOnlyInput
                  {...customFooterInputProps}
                  value={page.organization?.customFooterReceiptContent}
                  name="footer"
                  label="Footer"
                />
              )}
            </div>
          </div>

          <Preview subject={page} customBodyPreview={bodyPreview} customFooterPreview={footerPreview} />
        </div>
        <div className="actions-container">
          <button disabled={loading} type="button" className="button-outline" onClick={handleResetClick}>
            Not now
          </button>
          <button type="submit" className="button primary" disabled={loading || !pageReceiptBody}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </>
  );
}
