import React, { FC, useState } from 'react';
import { filterDuplicated } from 'utils';
import {
  findMultipleChoiceAnswers,
  removeAnswerChoice,
  removeAnswerOtherChoice,
  updateMultipleChoiceAnswer,
} from 'api/data/response';
import { Choice as ChoiceAnswer } from 'api/data/response/types';
import { QuestionBlock } from '../types';
import { Variant } from '~/components/PaymentBlock/types';
import '../style.scss';

interface MultipleChoiceProps {
  data: QuestionBlock;
  readOnly?: boolean;
  parent?: Variant;
}

const MultipleChoiceView: FC<MultipleChoiceProps> = ({ data, readOnly, parent }: MultipleChoiceProps) => {
  const answers = findMultipleChoiceAnswers(data.id);
  const [otherAnswer, setOtherAnswer] = useState<string>(answers['Other'] ? answers['Other'].value : '');

  const handleAnswerChange = (value: string, choice: ChoiceAnswer) => {
    updateMultipleChoiceAnswer(data.id, {
      value: value,
      choice: {
        id: choice.id,
        title: choice.title,
      },
      question: {
        id: data.id,
        title: data.questionPrompt,
        type: data.questionType,
      },
      parent: parent
        ? {
            id: parent.id,
            blockId: parent.blockId || '',
            title: parent.label || '',
            type: 'VARIANT',
          }
        : undefined,
    });
  };

  return (
    <div className="question-choice-container">
      <>
        {data.choices.filter(filterDuplicated).map(
          choice =>
            choice.text && (
              <label className="choice" key={choice.id} data-id={`question_choice_${choice.id}`}>
                <input
                  type="checkbox"
                  checked={!!answers[choice.id]}
                  name={data.id}
                  onChange={event => {
                    if (!readOnly) {
                      if (event.target.checked) {
                        handleAnswerChange(choice.text, { id: choice.id, title: choice.text });
                      } else {
                        removeAnswerChoice(choice.id);
                      }
                    }
                  }}
                />
                <span data-id={`question_choice_${choice.id}`}>{choice.text}</span>
              </label>
            ),
        )}
        {data.allowOther && (
          <label className="choice">
            <input
              type="checkbox"
              checked={!!answers['Other']}
              name={data.id}
              onChange={event => {
                if (!readOnly) {
                  if (event.target.checked) {
                    handleAnswerChange(otherAnswer, { title: 'Other' } as ChoiceAnswer);
                  } else {
                    removeAnswerOtherChoice(answers['Other']);
                  }
                }
              }}
            />
            Other:
            <input
              type="text"
              className="focus-item"
              value={otherAnswer}
              onChange={({ target }) => {
                const value = target.value.trimStart();
                setOtherAnswer(value);
                handleAnswerChange(value, { title: 'Other' } as ChoiceAnswer);
              }}
              maxLength={1000}
            />
          </label>
        )}
      </>
    </div>
  );
};

export default MultipleChoiceView;
