import React, { FC, useRef } from 'react';
import clsx from 'clsx';
import { Link, useLocation, useParams } from 'wouter';
import useParticipantLoggedIn from 'hooks/useParticipantLoggedIn';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import DropDownItemDivider from 'components/Menu/DropDownItemDivider';
import SafeHtmlRenderer from '../SafeHtmlRenderer';
import { Participant } from 'api/data/pages/types';
import profilePicture from 'assets/empty-profile-picture.png';
import './style.scss';

interface FundraiserParticipantProps {
  participant: Participant | null;
  showRequiredMessage?: boolean;
  participants: Participant[];
}

type PageParams = {
  id: string;
  participantSlug?: string;
};

export const FUNDRAISER_BLOCK_ID = 'block-fundraiser-participant-id';

const FundraiserParticipant: FC<FundraiserParticipantProps> = ({
  participant: participantParam,
  showRequiredMessage,
  participants,
}) => {
  const { id, participantSlug } = useParams<PageParams>();
  const [, setLocation] = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);

  const { logged: allowEdit } = useParticipantLoggedIn({ id, participantSlug });

  const mainPage = !participantParam;
  const participant = participantParam || {
    pictureUrl: '',
    message: '',
    fullName: 'Select a member',
  };

  return (
    <>
      <DropDown
        className={clsx('fundraiser-participant dropdown-participant', {
          'main-page': mainPage,
          required: showRequiredMessage,
        })}
        outsideRef={modalRef}
        openedIcon="chevron_up"
        closedIcon="chevron_down"
        iconSide="RIGHT"
        buttonChildren={
          <div id={FUNDRAISER_BLOCK_ID} className="row">
            <ProfileImage imageUrl={participant.pictureUrl} alt="participant" />
            <div className="participant-message">
              <span className="subtitle-small">{participant.fullName || 'Participant'} </span>
              {!mainPage && <br />}
              <SafeHtmlRenderer content={participant.message} />
              {allowEdit && <Link href={`/${id}/p/${participantSlug}/edit`}>Edit</Link>}
            </div>
          </div>
        }>
        <>
          <span className="subtitle-small">Change Participant</span>
          <DropDownItemDivider />
          {participants.map(member => (
            <DropDownItem
              key={member.id}
              onClick={() => {
                setLocation(`/${id}/p/${member.slug}`);
              }}>
              <ProfileImage className="member-item" imageUrl={member.pictureUrl} alt="member" />
              {member.fullName}
            </DropDownItem>
          ))}
        </>
      </DropDown>
      {showRequiredMessage && <span className="required-field">Please select a member to donate</span>}
    </>
  );
};

const ProfileImage = ({ imageUrl, alt, className }: { imageUrl: string | null; alt: string; className?: string }) => {
  return (
    <div className={clsx('img-container', className)}>
      <img className="profile-picture" src={imageUrl || profilePicture} alt={alt} />
    </div>
  );
};

export default FundraiserParticipant;
