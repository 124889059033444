import React, { FC } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import Wrapper from './Wrapper';
import { WrapperProps } from './Wrapper';
import './style.scss';

type NumberProps = WrapperProps & NumericFormatProps;

const Number: FC<NumberProps> = ({ name, label, className, hint, rules, labelTooltip, ...inputProps }) => {
  return (
    <Wrapper label={label} name={name} className={className} hint={hint} rules={rules} labelTooltip={labelTooltip}>
      <NumericFormat prefix="$" className="default" {...inputProps} type="text" name={name} id={name} />
    </Wrapper>
  );
};

export default Number;
