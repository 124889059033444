import React, { FC, useState, ChangeEvent } from 'react';
import dayjs from 'dayjs';
import { validateEmail } from 'utils';
import DatePicker from 'components/Input/DatePicker';
import Email from '~/components/Input/Email';
import Input from 'components/Input';
import PageWithLogoLine from 'components/PageWithLogoLine';

export type Participant = {
  id?: string;
  email: string;
  fullName: string;
  birthDate: string | Date | null;
};

type SignupParticipantProps = {
  handleSubmit: (participant: Participant) => Promise<void>;
  showFields: boolean;
  loading: boolean;
  email?: string;
};

const SignupParticipant: FC<SignupParticipantProps> = ({ handleSubmit, showFields, loading, email }) => {
  const [participant, setParticipant] = useState({
    email: email || '',
    fullName: '',
    birthDate: null,
  });
  const [ageError, setAgeError] = useState(false);

  const handleParticipant = ({ target }: ChangeEvent<HTMLInputElement>) => {
    handleOnChange(target.name, target.value);
  };

  const handleOnChange = (attr: string, value: string | Date) => {
    if (attr === 'birthDate') {
      const yearsOld = dayjs(new Date()).diff(value, 'year');
      setAgeError(yearsOld < 13);
    }

    setParticipant({
      ...participant,
      [attr]: value,
    });
  };

  const emailPresent = !!email;

  const createButtonValidation =
    !validateEmail(participant.email) ||
    (showFields && (!participant.birthDate || !participant.fullName)) ||
    ageError ||
    loading;

  const buttonText = showFields ? 'Create my free account' : 'Continue';

  return (
    <PageWithLogoLine>
      <form
        onSubmit={event => {
          event.preventDefault();
          void handleSubmit(participant);
        }}
        aria-label="participant form">
        <p className="title">Let&apos;s start with the basics</p>
        <div>
          <Email
            onChange={({ target }) => handleOnChange('email', target.value)}
            value={participant.email}
            name="email"
            onBlur={({ target }) => handleOnChange('email', target.value)}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={emailPresent}
          />
          {showFields && (
            <>
              <Input
                label="Full name"
                placeholder="Full Name"
                value={participant.fullName}
                onChange={handleParticipant}
                onBlur={({ target }) => handleOnChange(target.name, target.value.trim())}
                name="fullName"
                hint="This name will be shown on your page"
              />
              <DatePicker
                label="Birthday"
                name="birthDate"
                selected={participant.birthDate}
                onChange={(date: Date) => handleOnChange('birthDate', date)}
                placeholderText="Select..."
                pickerClass="default"
                rules={{
                  hasError: ageError,
                  errorMessage: 'You must be at least 13. Please have your parent/guardian sign up.',
                }}
              />
            </>
          )}
          <div className="footer">
            <button disabled={createButtonValidation}>{loading ? 'Loading...' : buttonText}</button>
          </div>
        </div>
      </form>
    </PageWithLogoLine>
  );
};

export default SignupParticipant;
