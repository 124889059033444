import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import { formatOrder, numberCentsFormatter } from 'utils';
import { responseVar, updateResponse, updateOrder } from 'api/data/response';
import * as ResponseQuery from 'graphql/response.graphql';
import Message from 'components/Message';
import Modal from 'components/Modal';
import OrderItemDetails from 'components/OrderItem';
import QuantityModal from './QuantityModal';
import TipModal from '../TipModal';
import VariantQuantity from 'components/Variant/VariantQuantity';
import { Order, OrderItem } from 'api/data/response/types';
import { Variant as DonationVariant } from 'components/DonationBlock/types';
import { PaymentBlock, PaymentOption, Variant as PaymentVariant } from 'components/PaymentBlock/types';
import { BlockState, BlockStateRequired } from 'api/data/pages/blocks/types';
import { ResponseData } from 'api/data/response/types';
import checkoutBag from 'assets/checkout_bag.svg';
import arrowDown from 'assets/arrow_down.svg';
import './style.scss';

type PriceByPaymentMethod = {
  paymentMethod: string;
  totalCents?: number;
  processingFeeCents?: number;
};

interface ItemsDetailsModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  order: Order;
  hasTip: boolean;
  blocks: BlockState[];
  orderIsProcessed: boolean;
  priceByPaymentMethod?: PriceByPaymentMethod;
}

interface ItemsDetailsProps {
  order: Order;
  hasTip: boolean;
  orderIsProcessed: boolean;
  blocks: BlockState[];
  priceByPaymentMethod?: PriceByPaymentMethod;
}

type QtyModalInfoType = {
  orderItem: OrderItem;
  isRequired: boolean;
};

export const ItemDetails: FC<ItemsDetailsProps> = ({
  order,
  hasTip,
  orderIsProcessed,
  blocks,
  priceByPaymentMethod,
}: ItemsDetailsProps) => {
  const [showTipModal, setShowTipModal] = useState(false);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);
  const [showItemRemovedMessage, setShowItemRemovedMessage] = useState(false);
  const [selectedQtyModalInfo, setSelectedQtyModalInfo] = useState<QtyModalInfoType>();
  const [saveResponse, { loading: loadingSaveResponse }] = useMutation<ResponseData>(ResponseQuery.SaveResponse);
  const loadingText = <span>Loading...</span>;
  const orderTipPercentage = order.tip.percentage * 100;

  const handleOnSaveResponse = async () => {
    const response = responseVar();

    const formattedOrder = formatOrder(response.order);

    const { data } = await saveResponse({
      variables: { response: { id: response.id, pageId: response.pageId, order: formattedOrder } },
    });

    if (data?.saveResponse?.id) {
      updateResponse({
        id: data?.saveResponse?.id,
      });

      if (data?.saveResponse?.order) {
        updateOrder(data.saveResponse.order);
      }
    }
  };

  return (
    <div className="modal-body-items">
      <TipModal
        showModal={showTipModal}
        setShowModal={setShowTipModal}
        order={order}
        saveResponse={() => void handleOnSaveResponse()}
      />
      <div className="checkout-detail-header">
        <img src={checkoutBag} alt="checkout bag" />
        Your items
      </div>
      <Message className="item-removed" showMessage={showItemRemovedMessage} setShowMessage={setShowItemRemovedMessage}>
        Item removed
      </Message>
      {selectedQtyModalInfo && (
        <QuantityModal
          showModal={showRemoveItemModal}
          setShowModal={() => setShowRemoveItemModal(!showRemoveItemModal)}
          isTheLastChargeBlock={order.orderItems.length === 1}
          setShowItemRemovedMessage={setShowItemRemovedMessage}
          saveResponse={() => void handleOnSaveResponse()}
          {...selectedQtyModalInfo}
        />
      )}
      {order.orderItems.map(orderItem => {
        const block = blocks.find(item => item.id === orderItem.blockId);
        const variant = (block as PaymentBlock)?.variants?.find(
          (item: DonationVariant | PaymentVariant) => item.id === orderItem.variant.id,
        ) as DonationVariant | PaymentVariant;
        const paymentOption = (variant as PaymentVariant)?.paymentOptions?.find(
          (item: PaymentOption) => item.id === orderItem.variant.paymentOptionId,
        ) as PaymentOption;
        const allowAnyAmount =
          block?.type === 'PAYMENT' ? paymentOption?.allowAnyAmount : (variant as DonationVariant)?.allowAnyAmount;

        return (
          <OrderItemDetails key={orderItem.id} orderItem={orderItem} block={block}>
            {variant && !allowAnyAmount && !orderIsProcessed ? (
              <div className="checkout-detail-variant">
                <VariantQuantity
                  variantId={variant.id}
                  allowAnyAmount={allowAnyAmount}
                  maxQuantity={(variant as PaymentVariant)?.maxQuantity}
                  availableQuantity={
                    (variant as PaymentVariant)?.inventory?.unlimited
                      ? null
                      : (variant as PaymentVariant).inventory?.availableQuantity
                  }
                  isRequired={(block as BlockStateRequired).required}
                  orderItem={orderItem}
                  onQuantityChangedToZero={() => {
                    setShowRemoveItemModal(true);
                    setSelectedQtyModalInfo({
                      orderItem,
                      isRequired:
                        (block as BlockStateRequired).required &&
                        order.orderItems.filter(item => item.blockId === orderItem.blockId).length === 1,
                    });
                  }}
                  saveResponse={() => void handleOnSaveResponse()}
                  disabled={loadingSaveResponse}
                  plusMinusClassName="secondary"
                />
              </div>
            ) : (
              <span className="text">Qty {orderItem.variant.quantity}</span>
            )}
          </OrderItemDetails>
        );
      })}
      <div className="order-item-details">
        <span></span>
        <div className="description-container">
          <div className="row space-between sub-title line">
            <span>Subtotal</span>
            {loadingSaveResponse ? loadingText : <strong>${numberCentsFormatter(order.subtotalCents)}</strong>}
          </div>
          {hasTip && (
            <div className="row space-between text">
              <span>
                Tip to Omella
                <span
                  className={clsx({ 'is-processed': orderIsProcessed })}
                  role="presentation"
                  onClick={() => setShowTipModal(orderIsProcessed ? false : true)}>
                  {Number.isInteger(orderTipPercentage) ? orderTipPercentage : orderTipPercentage.toFixed(2)}%
                  {!orderIsProcessed && <img src={arrowDown} alt="arrow down" />}
                </span>
              </span>
              <span className="recurring-name">${numberCentsFormatter(order.tip.amountCents)}</span>
            </div>
          )}
          <div className="row space-between text line">
            <span>{priceByPaymentMethod?.paymentMethod === 'card' ? 'Card processing fee' : 'Processing fee'}</span>
            {loadingSaveResponse ? (
              loadingText
            ) : (
              <span>
                {priceByPaymentMethod?.processingFeeCents && priceByPaymentMethod?.processingFeeCents > 0
                  ? numberCentsFormatter(priceByPaymentMethod?.processingFeeCents)
                  : 'Free'}
              </span>
            )}
          </div>
          <div className="row space-between title">
            <span>Total</span>
            {loadingSaveResponse ? (
              loadingText
            ) : (
              <strong>${numberCentsFormatter(priceByPaymentMethod?.totalCents || 0)}</strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ItemsDetailsModal: FC<ItemsDetailsModalProps> = ({
  showModal,
  setShowModal,
  order,
  hasTip,
  blocks,
  orderIsProcessed,
  priceByPaymentMethod,
}: ItemsDetailsModalProps) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <>
      <Modal
        header="Your items"
        headerIcon="checkout_bag"
        className="items-detail-modal"
        handleOnCancel={handleCancel}
        visible={showModal}
        position="top"
        fullScreenMobile>
        <ItemDetails
          order={order}
          hasTip={hasTip}
          orderIsProcessed={orderIsProcessed}
          blocks={blocks}
          priceByPaymentMethod={priceByPaymentMethod}
        />
      </Modal>
    </>
  );
};

export default ItemsDetailsModal;
