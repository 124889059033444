import React, { FC, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useMutation } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';

type PageParams = {
  inviteId: string;
};

interface ClaimInviteType {
  claimInvite:
    | {
        action: 'SETUP_PASSWORD';
        id: string;
        token: string;
      }
    | {
        action: 'LOGIN';
        token: string;
      };
}

const ClaimInvite: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [claimInviteMutation, { error }] = useMutation<ClaimInviteType>(OrganizationQuery.ClaimInvite, {
    onError: () => null,
  });
  const [, setLocation] = useLocation();

  useEffect(() => {
    const redirectToPage = async () => {
      const { data } = await claimInviteMutation({ variables: { inviteId: params.inviteId } });
      if (data?.claimInvite) {
        if (data.claimInvite.action === 'SETUP_PASSWORD') {
          setLocation(`/welcome/${data.claimInvite.id}/${data.claimInvite.token}`);
        } else {
          setLocation(`/tlogin?token=${data.claimInvite.token}`);
        }
      }
    };

    if (params.inviteId) {
      void redirectToPage();
    }
  }, [params.inviteId, claimInviteMutation, setLocation]);

  if (error) return <p>The link you tried to access has expired or is invalid.</p>;

  return <p>Loading...</p>;
};

export default ClaimInvite;
