import React, { FC, useState } from 'react';
import isURL from 'validator/lib/isURL';
import clsx from 'clsx';
import { updateAnswer } from 'api/data/response';
import ICONS from 'components/Icons';
import { Group } from 'api/data/response/types';
import { QuestionBlock } from '../types';
import './style.scss';

interface LinkProps {
  data: QuestionBlock;
  answerValue: string;
  readOnly: boolean;
  group?: Group;
  saveGroupedAnswer?: (value: string) => void;
}

const Link: FC<LinkProps> = ({ data, group, answerValue, readOnly, saveGroupedAnswer }) => {
  const [link, setLink] = useState(answerValue);
  const [invalidLink, setInvalidLink] = useState(link ? !isURL(link) : false);

  const handleAnswerChange = (link = '') => {
    setInvalidLink(!isURL(link));
    setLink(link);

    if (group && group.id && saveGroupedAnswer) {
      saveGroupedAnswer(link);
    } else {
      updateAnswer(data.id, {
        value: link,
        question: {
          id: data.id,
          title: data.questionPrompt,
          type: data.questionType,
        },
        file: undefined,
      });
    }
  };

  return (
    <>
      <div className="link">
        {ICONS['link']}
        <input
          className={clsx('input-answer', {
            'has-error': invalidLink,
          })}
          type="text"
          disabled={readOnly}
          placeholder={readOnly ? 'Recipient will add a link here' : 'example.com'}
          maxLength={1000}
          aria-label="input answer"
          value={link}
          onChange={({ target }) => handleAnswerChange(target.value)}
        />
      </div>
      {invalidLink && <p className="error-message">Please enter a valid URL</p>}
    </>
  );
};

export default Link;
