import { PageAnswer } from '~/api/data/response/types';
import { Participant } from '~/api/data/pages/types';

const participantAsAnAnswer = (participant: Partial<Participant>): PageAnswer => {
  return {
    question: 'Participant',
    answer: participant.user?.fullName || '',
    questionType: 'profile',
    description: '',
    isFile: false,
  };
};

export default participantAsAnAnswer;
