import React, { FC, useCallback, useState } from 'react';
import * as PageQuery from 'graphql/page.graphql';
import { useMutation } from '@apollo/client';
import IconButton from '../Button/IconButton';
import Input from '../Input';
import ICONS from '../Icons';
import Modal from '../Modal';
import ToggleSwitch from '../ToggleSwitch';
import { ResponseActivityType, SaveResponseActivityType } from '~/api/data/response/types';
import './style.scss';

export type ResponseActivityTypeModalProps = {
  onClose: () => void;
  visible: boolean;
  pageId: string;
  activityType?: Partial<ResponseActivityType>;
  spaceSlug?: string;
};

const ResponseActivityTypeModal: FC<ResponseActivityTypeModalProps> = ({
  onClose,
  visible,
  pageId,
  activityType: activityTypeProp,
  spaceSlug,
}) => {
  const [saveResponseActivityType] = useMutation<SaveResponseActivityType>(PageQuery.SaveResponseActivityType, {
    refetchQueries: [
      {
        query: PageQuery.GetPage,
        variables: { id: pageId },
      },
    ],
  });

  const [activityType, setActivityType] = useState(activityTypeProp);
  const [dirty, setDirty] = useState(false);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  const saveActivityType = useCallback(
    (aType: Partial<ResponseActivityType>) => {
      void saveResponseActivityType({
        variables: {
          id: aType?.id,
          pageId,
          name: aType?.name,
          public: aType?.public,
          deletedAt: aType?.deletedAt,
          currentSpace: spaceSlug,
          autoTracking: aType?.autoTracking,
        },
      }).then(res => {
        if (!aType?.id)
          setActivityType(activityType => {
            return { ...activityType, id: res.data?.saveResponseActivityType.id } as Partial<ResponseActivityType>;
          });
      });
      !!aType?.deletedAt && onClose && onClose();
    },
    [saveResponseActivityType, setActivityType, onClose, pageId, spaceSlug],
  );

  const handleSave = () => {
    activityType && saveActivityType(activityType);
    onClose && onClose();
  };

  const handleDelete = () => {
    if (activityType?.hasResponseActivities) setConfirmDeleteVisible(true);
    else deleteAction();
  };

  const deleteAction = () => {
    saveActivityType({ ...activityType, deletedAt: 'true' });
    onClose && onClose();
  };

  const hasError = (activityType?.name || '').trim().length === 0;

  return (
    <>
      <Modal
        visible={visible}
        className="response-activity-type-modal"
        header={<>{activityType?.id ? 'Edit' : 'New'} Action</>}
        confirmlabel="Save"
        denyLabel="Cancel"
        handleOnCancel={() => onClose && onClose()}
        handleOnConfirm={handleSave}
        confirmButtonProps={{ disabled: hasError }}
        fullScreenMobile>
        <Input
          label="Button name"
          placeholder="Name"
          onChange={({ target }) => {
            setActivityType({ ...activityType, name: target.value });
            setDirty(true);
          }}
          value={activityType?.name || ''}
          name="name"
          type="text"
          className="name"
          rules={{
            hasError: dirty && hasError,
            errorMessage: 'Please enter a valid name.',
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <div>
          <p className="label-title">Track this action</p>
          <div className="row align-center auto-tracking">
            <input
              type="radio"
              id="auto-tracking-false"
              checked={!activityType?.autoTracking || false}
              onChange={() => {
                setActivityType({ ...activityType, autoTracking: false });
                setDirty(true);
              }}
            />
            <label htmlFor={'auto-tracking-false'}>Only after the button is pressed</label>
          </div>
          <div className="row align-center auto-tracking">
            <input
              type="radio"
              id="auto-tracking-true"
              checked={activityType?.autoTracking}
              onChange={() => {
                setActivityType({ ...activityType, autoTracking: true });
                setDirty(true);
              }}
            />
            <label htmlFor={'auto-tracking-true'}>As soon as an authorized user scans the QR code</label>
          </div>
        </div>
        <div className="input-wrapper button-public">
          <div className="row align-center">
            {ICONS['users']}
            <ToggleSwitch
              label="Make button public"
              toggleValue={activityType?.public || false}
              handleOnChange={() => {
                setActivityType({ ...activityType, public: !activityType?.public });
                setDirty(true);
              }}
            />
            <p>{activityType?.public ? 'On' : 'Off'}</p>
          </div>
          <p>
            {activityType?.public
              ? 'Payers or anyone who views this order can press the button.'
              : 'Only logged in users with access to this space can press the button.'}
          </p>
        </div>
        {activityType?.id && (
          <div>
            <IconButton icon="delete" onClick={handleDelete} className="delete-button">
              Delete Action
            </IconButton>
            {confirmDeleteVisible && (
              <div className="confirm-delete-message">
                {ICONS['warning']}
                <p>
                  If you delete this action, you will lose the data associated with it. Are you sure? &nbsp;&nbsp;
                  <button className="button-link" onClick={deleteAction}>
                    Yes, delete please!
                  </button>
                </p>
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};

export default ResponseActivityTypeModal;
