import React, { FC, useState, useRef, ChangeEvent } from 'react';
import clsx from 'clsx';
import { findAnswer, updateAnswer } from 'api/data/response';
import uploadFile from 'api/uploadClient';
import { isAxiosError } from 'utils';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import uploadIcon from 'assets/upload.svg';
import { Answer, File, Group } from 'api/data/response/types';
import { QuestionBlock } from '../types';
import './style.scss';

const DEFAULT_ERROR = 'Error loading, please try again';
type FileUploadErrorResponse = {
  error: string;
};

interface UploadProps {
  data: QuestionBlock;
  placeholder: string;
  setUploadError: (uploadError: string) => void;
  readOnly: boolean;
  group?: Group;
  groupedAnswer?: Answer;
  pageId?: string;
  saveGroupedAnswer?: (value: string) => void;
}

const Upload: FC<UploadProps> = ({
  data,
  placeholder,
  setUploadError,
  groupedAnswer,
  readOnly,
  group,
  pageId,
  saveGroupedAnswer,
}) => {
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'FILE_UPLOAD');
  const fileInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const answerFileName = answer.file?.fileName || '';

  const handleOnClickBrowse = () => {
    if (readOnly || answerFileName || loading) return;
    if (fileInput && fileInput.current) {
      fileInput.current.click();
    }
  };

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUploadError('');

    if (fileInput && fileInput.current && fileInput.current.files) {
      setLoading(true);
      uploadFile(fileInput.current.files[0], 'FILE', '', pageId)
        .then(uploadResponse => {
          handleAnswerChange({
            fileName: uploadResponse.file_name || '',
            url: uploadResponse.url,
            id: uploadResponse.file_handle,
          });
        })
        .catch(error => {
          handleAnswerChange();
          if (isAxiosError<FileUploadErrorResponse>(error) && error.response) {
            setUploadError(error.response.data.error);
          } else {
            setUploadError(DEFAULT_ERROR);
          }
        })
        .finally(() => {
          event.target.value = '';
          setLoading(false);
        });
    }
  };

  const handleAnswerChange = (file?: File) => {
    if (group && group.id && saveGroupedAnswer) {
      saveGroupedAnswer(file?.fileName || '');
    } else {
      updateAnswer(data.id, {
        value: file?.fileName || '',
        question: {
          id: data.id,
          title: data.questionPrompt,
          type: data.questionType,
        },
        file,
      });
    }
  };

  const handleRemoveFile = () => {
    handleAnswerChange(undefined);
  };

  return (
    <>
      <div
        className={clsx('upload-box', { 'file-uploaded': !!answerFileName })}
        role="presentation"
        onClick={handleOnClickBrowse}>
        {answerFileName ? (
          <div>
            <div>
              {ICONS['check']}
              <span>{answerFileName}</span>
            </div>
            <IconButton icon="close" onClick={handleRemoveFile} />
          </div>
        ) : (
          <>
            <img src={uploadIcon} alt="Upload Icon" />
            <span>{loading ? 'Loading...' : placeholder}</span>
          </>
        )}
        <input
          type="file"
          ref={fileInput}
          style={{ display: 'none' }}
          onChange={onFileInputChange}
          data-testid="file-input"
        />
      </div>
    </>
  );
};

export default Upload;
