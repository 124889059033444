import React, { FC } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import { getIconByExtension } from 'utils';
import EXTENSIONS_ICONS from 'components/Icons/ExtensionsIcons';
import ICONS from 'components/Icons';
import { PageAnswer } from 'api/data/response/types';

const mapIconType: {
  [key: string]: string;
} = {
  group: 'group_icon',
  single_choice: 'single_choice_answer',
  multiple_choice: 'multiple_choice_answer',
  short_answer: 'short_answer',
  long_answer: 'long_answer',
  file_upload: 'file_upload',
  signature: 'signature',
  profile: 'profile_outline',
};

interface AnswersProps {
  respondentFullName: string;
  answers: PageAnswer[];
}

const Answers: FC<AnswersProps> = ({ respondentFullName, answers }) => {
  const downloadAs = (url: string, questionTitle: string) => {
    const extension = url.split('.').pop() || '';
    void axios
      .get(url, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'blob',
      })
      .then(response => {
        const aLink = document.createElement('a');
        const url = window.URL.createObjectURL(response.data as Blob);
        aLink.href = url;
        aLink.download = `${respondentFullName}-${questionTitle}.${extension}`;
        aLink.click();
      });
  };

  const fileUploadComponent = (pageAnswer: PageAnswer) => {
    if (pageAnswer.isFile) {
      const extension = pageAnswer.answer.split('.').pop() || '';
      return (
        <button
          className="button-clear"
          onClick={ev => {
            ev.stopPropagation();
            if (pageAnswer.answer) downloadAs(pageAnswer.answer, pageAnswer.question);
          }}>
          <img src={getIconByExtension(extension)} alt={extension} />
          <span>{`Download ${extension}`}</span>
        </button>
      );
    }

    const hrefLink =
      pageAnswer.answer.indexOf('http://') === 0 || pageAnswer.answer.indexOf('https://') === 0
        ? pageAnswer.answer
        : `http://${pageAnswer.answer}`;

    return (
      <a className="button-clear" href={hrefLink} target="_blank" rel="noreferrer" onClick={ev => ev.stopPropagation()}>
        <img src={EXTENSIONS_ICONS['link']} alt="link" />
        {pageAnswer.answer}
      </a>
    );
  };

  return (
    <>
      {answers.map((answer, index) => {
        return (
          <div key={index} className="answer-row">
            {ICONS[mapIconType[answer.questionType]]}
            <div className="answer-wrapper">
              <p className={clsx('question', { signature: answer.questionType === 'signature' })}>
                {answer.question}
                {!!answer.description && <p>{answer.description}</p>}
              </p>
              <p className={clsx('answer', { 'cursive-text': answer.questionType === 'signature' })}>
                {answer.questionType === 'file_upload' ? fileUploadComponent(answer) : answer.answer}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Answers;
