import React, { FC, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import { findAnswer } from 'api/data/response';
import useDebounce from '~/hooks/useDebounce';
import { Answer, ValidatePasscode } from 'api/data/response/types';
import { QuestionBlock } from '../types';

export interface PasscodeProps {
  data: QuestionBlock;
  placeholder: string;
  readOnly?: boolean;
  groupedAnswer?: Answer;
  handleAnswerChange?: (matchedPasscodeIds: string[], value: string) => void;
  usedPasscodes?: string[];
}

const Passcode: FC<PasscodeProps> = ({
  data,
  readOnly,
  placeholder,
  groupedAnswer,
  handleAnswerChange,
  usedPasscodes = [],
}: PasscodeProps) => {
  const [typedAnswer, setTypedAnswer] = useState<string>('');
  const debouncedAnswer = useDebounce<string>(typedAnswer);
  const isFirstRender = useRef(true);
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'PASSCODE');
  const [showMessage, setShowMessage] = useState(false);

  const { loading } = useQuery<ValidatePasscode>(ResponseQuery.ValidatePasscode, {
    variables: { blockId: data.id, passcode: debouncedAnswer },
    skip: isFirstRender.current && !debouncedAnswer,
    onCompleted: result => {
      isFirstRender.current = false;
      if (result && handleAnswerChange) {
        const validCodes = result.validatePasscode.filter(n => !usedPasscodes.includes(n));
        if (validCodes.length > 0) {
          handleAnswerChange(validCodes, typedAnswer);
          setShowMessage(false);
        } else {
          handleAnswerChange([], '');
          setShowMessage(true);
        }
      }
    },
  });

  return (
    <>
      <input
        className="input-answer"
        type="text"
        disabled={readOnly}
        placeholder={placeholder}
        maxLength={1000}
        aria-label="pass code"
        value={typedAnswer || answer.value}
        onChange={({ target }) => setTypedAnswer(target.value.trimStart())}
      />
      {loading && <span>loading...</span>}
      {!loading && debouncedAnswer.length > 0 && showMessage && (
        <span className="error-message">Please enter a valid answer to continue</span>
      )}
    </>
  );
};

export default Passcode;
