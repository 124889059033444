import React, { FC } from 'react';
import { Link } from 'wouter';
import { updateResponse } from 'api/data/response';
import { defaultResponse } from '~/defaults/response';
import { Response } from 'api/data/response/types';
import success_badge from 'assets/success_badge.svg';
import './style.scss';

export type SubmissionType = 'payment' | 'payment_sent' | 'response';

const successMessages: {
  [key: string]: { title: string; subTitle: string; emailText: string };
} = {
  payment: {
    title: 'Payment succeeded',
    subTitle: 'Thank you for your payment.',
    emailText: 'A receipt has been sent to ',
  },
  payment_sent: {
    title: 'Order Received',
    subTitle: 'Your payment is processing.',
    emailText: 'A receipt has been sent to ',
  },
  response: {
    title: 'Success!',
    subTitle: 'Thank you for your submission.',
    emailText: 'A confirmation email has been sent to ',
  },
};

interface Params {
  response: Response | undefined;
  submissionType: SubmissionType;
  participantSlug?: string;
}

const SuccessCard: FC<Params> = ({ response, submissionType, participantSlug }) => {
  const newResponseLink = participantSlug ? `/${response?.pageId}/p/${participantSlug}` : `/${response?.page?.slug}`;

  return (
    <div className="success-card">
      <div className="success-title">
        <img className="success-badge" src={success_badge} alt="Success" />
        <p
          className={submissionType === 'payment' || submissionType === 'response' ? 'success-label' : 'pending-label'}>
          {successMessages[submissionType].title}
        </p>
      </div>
      <p className="success-subtitle">{successMessages[submissionType].subTitle}</p>
      {response?.user?.email && (
        <p className="email-text">
          {successMessages[submissionType].emailText} {response.user.email}
        </p>
      )}
      {submissionType === 'response' && !response?.requestId && (
        <Link
          href={newResponseLink}
          className="link-new-response"
          onClick={() => updateResponse({ ...defaultResponse, id: undefined })}>
          Submit another response
        </Link>
      )}
    </div>
  );
};

export default SuccessCard;
